import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "代理管理資訊",
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "代理編號" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.number), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "代理帳號" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.username), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "建立人帳號" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.creator), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "建立時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.channelAgent?.createdAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "最後登入時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.lastLoginAt ? _ctx.formatLocalTime(_ctx.channelAgent?.lastLoginAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "手續費率" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((+_ctx.channelAgent?.feePercent).toFixed(2)) + "% ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "代理名稱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.name ? _ctx.channelAgent?.name : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "地址" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.address ? _ctx.channelAgent?.address : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "信箱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.email), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "電話" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.phone ? _ctx.channelAgent?.phone : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "年齡" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.age ? _ctx.channelAgent?.age : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "性別" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.gender ? _ctx.$t(`gender.${_ctx.channelAgent?.gender}`) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Line" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.line), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "TG" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.telegram ? _ctx.channelAgent?.telegram : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "備註" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.note ? _ctx.channelAgent?.note : '-'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_descriptions, {
        title: "代理管理資訊",
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "渠道總數" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.channelCount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "合作總數" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.channelAgent?.campaignCount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "實際合作總額" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((+_ctx.channelAgent?.campaignAmount).toFixed(2)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "訂閱總金額" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((+_ctx.channelAgent?.channelSubscriptionAmount).toFixed(2)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "分潤總金額" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((+_ctx.channelAgent?.channelCommissionAmount).toFixed(2)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}