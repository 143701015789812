
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import {
  getChannelAgent
} from '@/services/api';
import { ChannelAgent } from '@/interfaces';
import { formatLocalTime } from '@/utils/format-time';

export default defineComponent({
  setup() {
    const channelAgent = ref<ChannelAgent>();
    const channelAgentId = useRoute().params.id as string;

    const fetchChannelAgents = async() => {
      const { data } = await getChannelAgent({ channelAgentId });
      channelAgent.value = data;
    };

    onMounted(() => {
      fetchChannelAgents();
    });

    return {
      channelAgent,
      formatLocalTime
    };
  }
});
